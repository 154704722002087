import React, { useState, useRef } from "react";
import "./footer.scss";
import { motion } from "framer-motion";
import { MdEmail } from "react-icons/md";
import { BsTelephoneFill } from "react-icons/bs";
import {
	FaFacebookSquare,
	FaGithubSquare,
	FaLinkedin,
	FaTelegram,
} from "react-icons/fa";

import emailjs from "@emailjs/browser";
const Footer = () => {
	const form = useRef();

	const sendEmail = e => {
		emailjs
			.sendForm(
				"service_kwe7f5s",
				"template_zg7zrvr",
				form.current,
				"nA6y9Q0F6xzOH8aP3"
			)
			.then(
				result => {
					console.log(result.text);
				},
				error => {
					console.log(error.text);
				}
			);
	};

	const [animate, setAnimate] = useState(false);
	const [formData, setFormData] = useState({
		name: "",
		email: "",
		message: "",
	});
	const [isFormSubmitted, setIsFormSubmitted] = useState(false);
	const { username, email, message } = formData;

	const handleChangeInput = e => {
		const { name, value } = e.target;
		setFormData({ ...formData, [name]: value });
	};

	const handleReset = e => {
		Array.from(document.querySelectorAll(".pText")).forEach(e => {
			e.value = "";
		});
	};

	const handleSubmit = e => {
		e.preventDefault();
		setAnimate(true);
		setIsFormSubmitted(true);

		setTimeout(() => {
			setIsFormSubmitted(false);
			setAnimate(false);
			handleReset();
		}, 6000);
		sendEmail();
	};

	const d = new Date();
	const currentYear = d.getFullYear();

	return (
		<div className="footer " id="Contact">
			<motion.div
				whileInView={{ y: [50, 0], opacity: [0, 1] }}
				transition={{ duration: 0.75 }}
			>
				<h1 className="section_title">
					Let's keep <span> contact</span>
				</h1>
			</motion.div>

			<motion.div
				whileInView={{ y: [50, 0], opacity: [0, 1] }}
				transition={{ duration: 0.75 }}
				className="footer-cards flex"
			>
				<div className="footer-card">
					<MdEmail />
					<a href="mailto:edjalalov@outlook.com" className="">
						edjalalov@outlook.com
					</a>
				</div>
				<div className="footer-card">
					<BsTelephoneFill />
					<a href="tel: +998 (99) 438-77-09" className="p-text">
						+998 (99) 438-77-09
					</a>
				</div>
			</motion.div>

			{!isFormSubmitted ? (
				<motion.div
					whileInView={{ y: [50, 0], opacity: [0, 1] }}
					transition={{ duration: 0.75 }}
					className="form-container flex"
				>
					<form ref={form} onSubmit={handleSubmit} className="footer-form flex">
						<div>
							<input
								className="pText"
								type="text"
								placeholder="Your Full Name"
								name="name"
								value={username}
								onChange={handleChangeInput}
								required
							/>
						</div>
						<div>
							<input
								required
								className="pText"
								type="email"
								placeholder="Your Email"
								name="email"
								value={email}
								onChange={handleChangeInput}
							/>
						</div>
						<div>
							<textarea
								required
								className="pText"
								placeholder="Your Message"
								value={message}
								name="message"
								onChange={handleChangeInput}
							/>
						</div>
					</form>
				</motion.div>
			) : (
				<div className="thanks_text">
					<h2>Thank you for your interest.</h2>
					<h3>I will get back to you asap!</h3>
				</div>
			)}

			<div className="bottom_part">
				{/* Send button */}
				<motion.div
					whileInView={{ y: [50, 0], opacity: [0, 1] }}
					transition={{ duration: 0.75 }}
					className="button_div flex"
				>
					<button
						type="button"
						className={`success ${animate ? ["animate"] : ""}`}
						onClick={handleSubmit}
						disabled={!formData.email || !formData.name || !formData.message}
					>
						Send Message
					</button>
				</motion.div>

				{/* Social buttons */}

				<div className="footer-socials socials">
					<a
						aria-label="facebook"
						href="https://www.facebook.com/elyor.djalalov/"
						target="_blank"
						rel="noopener noreferrer"
					>
						<FaFacebookSquare />
					</a>
					<a
						aria-label="github"
						href="https://github.com/Djalalov"
						target="_blank"
						rel="noopener noreferrer"
					>
						<FaGithubSquare />
					</a>
					<a
						aria-label="linkedin"
						href="https://www.linkedin.com/in/eldjalalov/"
						target="_blank"
						rel="noopener noreferrer"
					>
						<FaLinkedin />
					</a>
					<a
						aria-label="telegram"
						href="https://t.me/ElyorbekDjalalov"
						target="_blank"
						rel="noopener noreferrer"
					>
						<FaTelegram />
					</a>
				</div>

				{/* Footer */}
				<footer>
					<div className="bottom_info flex ">
						<small>
							{" "}
							Designed & Built by <span>Elyor Djalalov</span>{" "}
						</small>
						<small>Copyright &copy; {currentYear}. All rights reserved.</small>
					</div>
				</footer>
			</div>
		</div>
	);
};

export default Footer;
