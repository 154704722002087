import React from "react";
import { images } from "../../constants";

/*======== Extracting the NAMES of the logos from backImgs ===========*/

const backArray = [
	images.NodeJS,
	images.MongoDB,
	images.ExpressJS,
	images.NextJS,
	images.Typescript,
	images.Graphql,
	images.Jest,
];

const backImgNames = [];

for (let i = 0; i < backArray.length; i++) {
	let backItem = backArray.at(i);
	let backPath = backItem.split("/");
	let foo = backPath[backPath.length - 1];
	let dotPosition = foo.indexOf(".");
	let imgName = foo.substring(0, dotPosition);
	backImgNames.push(imgName);
}
const Logos = () => {
	return (
		<>
			{backArray.map((skill, index) => (
				<div className="skills-circle flex" key={`skill-${index}`}>
					<div>
						<img src={skill} alt="skill" />
					</div>
					<span>{backImgNames[index]}</span>
				</div>
			))}
		</>
	);
};

export default Logos;
