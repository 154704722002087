import React from "react";
import { images } from "../../constants";

const frontArray = [
	images.HTML5,
	images.CSS3,
	images.Javascript,
	images.Sass,
	images.React,
	images.Redux,
	images.TailwindCSS,
	images.Bootstrap,
	images.FramerMotion,
];

const frontImgNames = [];

for (let i = 0; i < frontArray.length; i++) {
	let fronItem = frontArray.at(i);
	let frontPath = fronItem.split("/");
	let foo = frontPath[frontPath.length - 1];
	let dotPosition = foo.indexOf(".");
	let imgName = foo.substring(0, dotPosition);
	frontImgNames.push(imgName);
}

const Logos = () => {
	return (
		<>
			{frontArray.map((skill, index) => (
				<div className="skills_circle flex" key={`skill-${index}`}>
					<div>
						<img src={skill} alt="skill" />
					</div>
					<span>{frontImgNames[index]}</span>
				</div>
			))}
		</>
	);
};

export default Logos;
