import React, { useState, useEffect } from "react";
import "./portfolio.scss";
import { motion } from "framer-motion";
import { FaEye, FaGithub } from "react-icons/fa";
import { works } from "../../constants/projects.js";

const Portfolio = () => {
	const [items, setItems] = useState([]);
	const [activeFilter, setActiveFilter] = useState("All");
	const [animateCard, setAnimateCard] = useState({ y: 0, opacity: 1 });
	const [filterWork, setFilterWork] = useState([]);

	useEffect(() => {
		setItems(works);
		setFilterWork(works);
	}, []);

	const handleWorkFilter = item => {
		setActiveFilter(item);
		setAnimateCard([{ y: 100, opacity: 0 }]);

		setTimeout(() => {
			setAnimateCard([{ y: 0, opacity: 1 }]);

			if (item === "All") {
				setFilterWork(items);
			} else {
				setFilterWork(items.filter(work => work.tags.includes(item)));
			}
		}, 500);
	};

	return (
		<>
			<div className="portfolio container" id="Portfolio">
				<motion.div
					whileInView={{ y: [50, 0], opacity: [0, 1] }}
					transition={{ duration: 0.75 }}
				>
					<h1 className="section_title">
						Here is a <span> portfolio</span>
					</h1>
				</motion.div>

				<motion.div
					whileInView={{ y: [50, 0], opacity: [0, 1] }}
					transition={{ duration: 0.75 }}
					className="work-filter"
				>
					{["All", "Web App", "Next JS", "React JS", "JavaScript"].map(
						(item, index) => (
							<div
								key={index}
								onClick={() => handleWorkFilter(item)}
								className={`filter-item flex ${
									activeFilter === item ? "item-active" : ""
								}`}
							>
								{item}
							</div>
						)
					)}
				</motion.div>

				<motion.div
					whileInView={{ scale: [0.9, 1], opacity: [0, 1] }}
					transition={{ duration: 0.75 }}
				>
					<motion.div
						animate={animateCard}
						transition={{ duration: 0.35 }}
						className="work-portfolio"
					>
						{filterWork.map((work, index) => (
							<div className="work-item" key={index}>
								<div className="work-img flex">
									<img src={work.imageUrl} alt="works" />

									<motion.div
										whileHover={{ opacity: [0, 1] }}
										transition={{
											duration: 0.4,
											ease: "easeInOut",
											staggerChildren: 0.5,
										}}
										className="work-hover flex"
									>
										<a
											href={work.projectLink}
											target="_blank"
											rel="noreferrer"
											aria-label="Project Link"
										>
											<motion.div
												whileInView={{ scale: [0, 1] }}
												whileHover={{ scale: [1, 0.9] }}
												transition={{ duration: 0.25 }}
												className="flex"
											>
												<FaEye />
											</motion.div>
										</a>
										<a
											href={work.codeLink}
											target="_blank"
											rel="noreferrer"
											aria-label="Code Link"
										>
											<motion.div
												whileInView={{ scale: [0, 1] }}
												whileHover={{ scale: [1, 0.9] }}
												transition={{ duration: 0.25 }}
												className="flex"
											>
												<FaGithub />
											</motion.div>
										</a>
									</motion.div>
								</div>

								<div className="work-content flex">
									<div className="work-tag flex">
										<span>{work.tags[0]}</span>
									</div>
									<h1>{work.title}</h1>
									<small>{work.description}</small>

									<div className="stack">
										{work.stack.map(stack => (
											<span key={stack}>{stack}</span>
										))}
									</div>
								</div>
							</div>
						))}
					</motion.div>
				</motion.div>
			</div>
		</>
	);
};

export default Portfolio;
