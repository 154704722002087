import React, { useState } from "react";
import "./nav.scss";
import { IoPersonSharp, IoHome, IoBriefcase, IoSend } from "react-icons/io5";
import { GiAchievement } from "react-icons/gi";
import { motion } from "framer-motion";
import {
	FaFacebookSquare,
	FaGithubSquare,
	FaLinkedin,
	FaTelegram,
} from "react-icons/fa";

const Navbar = () => {
	const [active, setActive] = useState("#Home");

	return (
		<>
			<nav>
				{/* LOGO */}
				<motion.div
					whileInView={{ y: [-30, 0], opacity: [0, 1] }}
					transition={{ duration: 1 }}
					className="n-right"
				>
					<div className="navbar-logo">
						<h1>ED</h1>
						<motion.div
							whileInView={{ scale: [0.8, 1], opacity: [0, 1] }}
							transition={{ duration: 3, ease: "easeInOut" }}
							className="logoCircle"
						/>
					</div>

					{/* navbar links */}
					<motion.div
						whileInView={{ y: [-30, 0], opacity: [0, 1] }}
						transition={{ duration: 1 }}
					>
						<ul className="navbar-links">
							<li>
								<a
									href="#Home"
									className={`nav-link nav-link-ltr ${
										active === "#Home" ? "active" : ""
									}`}
									onClick={() => setActive("#Home")}
								>
									Home
								</a>
							</li>

							<li className="flex">
								<a
									href="#About"
									onClick={() => setActive("#About")}
									className={`nav-link nav-link-ltr 
							${active === "#About" ? "active" : ""}`}
								>
									About
								</a>
							</li>

							<li className="flex ">
								<a
									href="#Skills"
									className={`nav-link nav-link-ltr 
							${active === "#Skills" ? "active" : ""}`}
									onClick={() => setActive("#Skills")}
								>
									Skills
								</a>
							</li>

							<li className="flex">
								<a
									href="#Portfolio"
									className={`nav-link nav-link-ltr 
							${active === "#Portfolio" ? "active" : ""}`}
									onClick={() => setActive("#Portfolio")}
								>
									Portfolio
								</a>
							</li>

							<li className="flex ">
								<a
									href="#Contact"
									className={`nav-link nav-link-ltr 
							${active === "#Contact" ? "active" : ""}`}
									onClick={() => setActive("#Contact")}
								>
									Contact
								</a>
							</li>
						</ul>
					</motion.div>
				</motion.div>

				{/*MOBILE MENU ICONS*/}
				<motion.div
					whileInView={{ y: [-30, 0], opacity: [0, 1] }}
					transition={{ duration: 1 }}
					className="mobile-icons flex"
				>
					<a
						aria-label="Home"
						href="#Home"
						className={`${active === "#Home" ? "active" : ""}`}
						onClick={() => setActive("#Home")}
					>
						<IoHome />
					</a>
					<a
						aria-label="About"
						href="#About"
						onClick={() => setActive("#About")}
						className={`${active === "#About" ? "active" : ""}`}
					>
						<IoPersonSharp />
					</a>
					<a
						aria-label="Skills"
						href="#Skills"
						className={`${active === "#Skills" ? "active" : ""}`}
						onClick={() => setActive("#Skills")}
					>
						<GiAchievement />
					</a>
					<a
						aria-label="Potfolio"
						href="#Portfolio"
						className={`${active === "#Portfolio" ? "active" : ""}`}
						onClick={() => setActive("#Portfolio")}
					>
						<IoBriefcase />
					</a>
					<a
						aria-label="Contact"
						href="#Contact"
						className={`${active === "#Contact" ? "active" : ""}`}
						onClick={() => setActive("#Contact")}
					>
						<IoSend />
					</a>
				</motion.div>

				{/* social buttons */}
				<motion.div
					whileInView={{ y: [-30, 0], opacity: [0, 1] }}
					transition={{ duration: 1 }}
				>
					<div className="navbar-socials socials">
						<a
							aria-label="Facebook"
							href="https://www.facebook.com/elyor.djalalov/"
							target="_blank"
							rel="noopener noreferrer"
						>
							<FaFacebookSquare />
						</a>
						<a
							aria-label="Github"
							href="https://github.com/Djalalov"
							target="_blank"
							rel="noopener noreferrer"
						>
							<FaGithubSquare />
						</a>
						<a
							aria-label="LinkedIn"
							href="https://www.linkedin.com/in/eldjalalov/"
							target="_blank"
							rel="noopener noreferrer"
						>
							<FaLinkedin />
						</a>
						<a
							aria-label="Telegram"
							href="https://t.me/ElyorDjalalov"
							target="_blank"
							rel="noopener noreferrer"
						>
							<FaTelegram />
						</a>
					</div>
				</motion.div>
			</nav>
		</>
	);
};
export default Navbar;
