/* eslint-disable import/no-anonymous-default-export */
import CSS3 from "../assets/CSS3.webp";
import Figma from "../assets/Figma.webp";
import Git from "../assets/git.webp";
import Graphql from "../assets/Graphql.webp";
import HTML5 from "../assets/HTML5.webp";
import Javascript from "../assets/Javascript.webp";
import NodeJS from "../assets/NodeJS.webp";
import Python from "../assets/Python.webp";
import React from "../assets/React.webp";
import Redux from "../assets/Redux.webp";
import Sass from "../assets/Sass.webp";
import Typescript from "../assets/Typescript.svg";
import me from "../assets/me.webp";
import ElyorResume from "../assets/ElyorResume.pdf";
import front0 from "../assets/front0.webp";
import front1 from "../assets/front1.webp";
import FramerMotion from "../assets/FramerMotion.webp";
import Bootstrap from "../assets/Bootstrap.webp";
import TailwindCSS from "../assets/TailwindCSS.webp";
import skillsBg from "../assets/skillsBg.webp";
import back2 from "../assets/back2.webp";
import MongoDB from "../assets/MongoDB.webp";
import ExpressJS from "../assets/ExpressJS.webp";
import Mongoose from "../assets/Mongoose.webp";
import etc from "../assets/etc.webp";
import project1 from "../assets/project1.png";
import project2 from "../assets/project2.png";
import project4 from "../assets/project4.png";
import project5 from "../assets/project5.png";
import project6 from "../assets/project6.png";
import project3 from "../assets/project3.png";
import project7 from "../assets/project7.png";
import Github from "../assets/github.png";
import NextJS from "../assets/NextJS.png";
import NPM from "../assets/npm.svg";
import Jest from "../assets/Jest.webp";

export default {
	Jest,
	NextJS,
	project1,
	project3,
	project2,
	project4,
	project5,
	project6,
	project7,
	Github,
	NPM,
	etc,
	Mongoose,
	ExpressJS,
	MongoDB,
	back2,
	skillsBg,
	FramerMotion,
	ElyorResume,
	Bootstrap,
	TailwindCSS,
	me,
	CSS3,
	Figma,
	Git,
	Graphql,
	HTML5,
	Javascript,
	NodeJS,
	Python,
	React,
	Redux,
	Sass,
	Typescript,
	front0,
	front1,
};
