import React, { lazy, Suspense } from "react";
import "./App.scss";
//import Loader from "./components/Loader/Loader";
import About from "./container/About/About";
import Footer from "./container/Footer/Footer";
import Header from "./container/Header/Header";
import Portfolio from "./container/Portfolio/Portfolio";
import Navbar from "./components/Navbar/Navbar";
import ScrollUp from "./components/ScrollUp/ScrollUp";
import Skills from "./container/Skills/Skills";

/* const About = lazy(() => import("./container/About/About"));
const Footer = lazy(() => import("./container/Footer/Footer"));
const Header = lazy(() => import("./container/Header/Header"));
const Portfolio = lazy(() => import("./container/Portfolio/Portfolio"));
const Skills = lazy(() => import("./container/Skills/Skills"));
const Navbar = lazy(() => import("./components/Navbar/Navbar"));
const ScrollUp = lazy(() => import("./components/ScrollUp/ScrollUp"));
 */
const App = () => {
	return (
		<div className="app">
			<Header />
			<Navbar />
			<ScrollUp />

			<About />
			<Skills />

			<Portfolio />
			<Footer />
		</div>
	);
};

export default App;
