import React from "react";
import { motion } from "framer-motion";
import "./header.scss";

import { images } from "../../constants";

const Header = () => {
	return (
		<div className="header container" id="Home">
			<motion.div
				whileInView={{ scale: [0.9, 1], opacity: [0, 1] }}
				transition={{ duration: 1 }}
			>
				<div className="container_img">
					<div className="meCircle">
						<img src={images.me} alt="me" className="imgME" />
					</div>
				</div>
			</motion.div>
			<motion.div
				whileInView={{ scale: [0.9, 1], opacity: [0, 1] }}
				transition={{ duration: 1 }}
			>
				<div className="info-left ">
					<h3 className="hello">
						<span>👋</span> Hello I'm
					</h3>

					<h1>Elyor Djalalov</h1>

					<h2 className="job-text">Frontend / React Developer</h2>

					<p>
						I bring ideas to life with code. My main goal is to provide an
						exceptional digital experience that can ultimately make people's
						lives easier.
					</p>

					<div className="cta">
						<a href={""} download className="btn">
							Download CV
						</a>
						<a href="#Contact" className="btn-primary">
							Let's get in touch
						</a>
					</div>
				</div>
			</motion.div>
			<div className="icon-scroll"></div>
		</div>
	);
};

export default Header;
