import React from "react";
import "./about.scss";
import { motion } from "framer-motion";
import { BsEmojiSmile } from "react-icons/bs";
import { FaRegHandshake } from "react-icons/fa";
import { IoFootball } from "react-icons/io5";

const About = () => {
	return (
		<div className="about container" id="About">
			<motion.div
				whileInView={{ y: [50, 0], opacity: [0, 1] }}
				transition={{ duration: 1 }}
			>
				<h1 className="section_title">
					Info <span> about </span>
					me
				</h1>
			</motion.div>

			<motion.div
				whileInView={{ scale: [0.9, 1], opacity: [0, 1] }}
				transition={{ duration: 0.75 }}
			>
				<div className="info_sections">
					<div className="general">
						<div className="info_title">
							<h1>General</h1>
						</div>
						<div className="section_info">
							<div className="text_title">
								<h1>Hello there, again! </h1>
								<span>
									<BsEmojiSmile />
								</span>
							</div>

							<br />
							<p className="p-text">
								My fullname is Djalalov Elyor (you can call me just Elyor). I
								have been learning and programming since 2015, though with some
								breaks along the way. I have managed to contribute 10+ projects
								for businesses and have taken active role in developing other
								web platforms.
								<br />
								<br />
								Having started my career as a business manager, my childhood
								love for computers ultimately led me to my true passion for web
								development.
							</p>
						</div>
					</div>

					<div className="lifestyle">
						<div className="info_title">
							<h1>Lifestyle</h1>
						</div>
						<div className="section_info">
							<div className="text_title">
								<h1>Learning and sharing. </h1>
								<span>
									<FaRegHandshake />
								</span>
							</div>

							<br />
							<p className="p-text">
								I take much interest in learning new things for web development.
								Having a clear vision for businesses and web development, I have
								always been a non-stop learner to master my skills and currently
								I am into the backend technologies. I am very sociable and I
								like sharing ideas on web development and innovation.
								<br />
								<br />
								Above all, eating good food and having good friends are my
								energy sources.
							</p>
						</div>
					</div>
					<div className="interest">
						<div className="info_title">
							<h1 className="interest">Interest</h1>
						</div>
						<div className="section_info">
							<div className="text_title">
								<h1> I like...</h1>
								<span>
									<IoFootball />
								</span>
							</div>

							<br />
							<p className="p-text">
								Besides, in my spare time I would like to keep myself with
								sports of any kind as long as I do some phsyical activity. I am
								keen on eating out and enjoying with friends as well.
							</p>
						</div>
					</div>
				</div>
			</motion.div>
		</div>
	);
};

export default About;
