import "./scrollUp.scss";
import { useState, useEffect } from "react";
import { BsFillArrowUpSquareFill } from "react-icons/bs";
import { motion } from "framer-motion";

const ScrollUp = () => {
	const [isVisible, setIsVisible] = useState(false);
	const toggleVisibility = () => {
		if (window.pageYOffset > 500) {
			setIsVisible(true);
		} else {
			setIsVisible(false);
		}
	};

	const scrollToUp = () => {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});
	};

	useEffect(() => {
		window.addEventListener("scroll", toggleVisibility);
	});

	return (
		isVisible && (
			<>
				<motion.div
					whileInView={{ y: [50, 0], opacity: [0, 1] }}
					transition={{ duration: 1 }}
					className="scrollDiv"
				>
					<button
						onClick={scrollToUp}
						className="scrollBtn"
						aria-label="scroll Up"
					>
						<BsFillArrowUpSquareFill />
					</button>
				</motion.div>
			</>
		)
	);
};

export default ScrollUp;
