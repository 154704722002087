import React from "react";

import "./skills.scss";
import { motion } from "framer-motion";
import { images } from "../../constants";

//Logos Components
import FrontLogos from "./FrontLogos";
import BackLogos from "./BackLogos";

const Skills = () => {
	return (
		<div className="skills container" id="Skills">
			{/* ////////////////  FRONTEND SECTION /////////////// */}
			<div className="frontend flex">
				<motion.div
					whileInView={{ y: [50, 0], opacity: [0, 1] }}
					transition={{ duration: 1 }}
				>
					<h1 className="section_title special">
						Frontend <span> skills</span>
					</h1>
				</motion.div>

				<div className="sections">
					<motion.div
						whileInView={{ scale: [0.75, 1], opacity: [0, 1] }}
						transition={{ duration: 0.75, delayChildren: 0.5 }}
						className="sectionImg	flex"
					>
						<img className="frontImg flex" src={images.front1} alt="frontImg" />

						<motion.div
							whileInView={{ scale: [0.75, 1], opacity: [0, 1] }}
							transition={{ duration: 1.5, ease: "easeInOut" }}
							className="bgCircle"
						/>
					</motion.div>

					{/* LOGOS  */}
					<div className="logos">
						<motion.div
							whileInView={{ scale: [0.8, 1], opacity: [0, 1] }}
							className="skills-logos"
						>
							<FrontLogos />
						</motion.div>
					</div>
				</div>
			</div>

			{/* ////////////////  BACKEND SECTION /////////////// */}
			<div className="backend">
				<motion.div
					whileInView={{ y: [50, 0], opacity: [0, 1] }}
					transition={{ duration: 1 }}
				>
					<h1 className="section_title">Backend and others </h1>
				</motion.div>
				<div className="sections">
					<div className="logos">
						<motion.div
							whileInView={{ scale: [0.8, 1], opacity: [0, 1] }}
							className="skills-logos"
						>
							<BackLogos />
						</motion.div>
					</div>

					<motion.div
						whileInView={{ scale: [0.75, 1], opacity: [0, 1] }}
						transition={{ duration: 1, delayChildren: 0.5 }}
						className="sectionImg sectionImg2"
					>
						<img className="backImg" src={images.back2} alt="backImg" />

						<motion.div
							whileInView={{ scale: [0.75, 1], opacity: [0, 1] }}
							transition={{ duration: 1.5, ease: "easeInOut" }}
							className="bgCircle bgCircle2"
						/>
					</motion.div>
				</div>
			</div>
		</div>
	);
};

export default Skills;
