//import { images } from "./images";
import { images } from ".././constants";

export const works = [
	{
		title: "Personal web page",
		description: "A modern design, comfortable navigation and fully responsive",
		projectLink: "https://elyor.vercel.app/",
		codeLink: "https://github.com/Djalalov/personal-web",
		imageUrl: images.project1,
		tags: ["React JS", "JavaScript"],
		stack: ["React", "Sass", "FramerMotion"],
	},
	{
		title: "Uz Video App",
		description:
			"Simple video app with full of features offering the latest trailers of top movies.",
		projectLink: "https://uz-video.vercel.app",
		codeLink: "https://github.com/Djalalov/uz-video",
		imageUrl: images.project2,
		tags: ["Web App", "Next JS"],
		stack: ["Next js", "TailwindCSS", "Firebase v9", "Recoil"],
	},

	{
		title: "Social App",
		description:
			"Fully responsive and disgned with features login/logout, making posts, commenting, like/unlike, etc.",
		projectLink: "https://social-el.vercel.app",
		codeLink: "https://github.com/Djalalov/social-app",
		imageUrl: images.project3,
		tags: ["Web App", "Next JS"],
		stack: ["Next Js", "TailwindCSS", "Firebase v9", "Recoil Js"],
	},

	{
		title: "E-commerce",
		description: "A modern e-commerce web app",
		projectLink: "https://elshop.vercel.app/",
		codeLink: "https://github.com/Djalalov/elshop",
		imageUrl: images.project4,
		tags: ["React JS"],
		stack: ["ReactJS", "NodeJS", "MongoDB", "ExpressJS", "Redux"],
	},
	{
		title: "Airbnb",
		description: "Landing page of Airbnb (previous version)",
		projectLink: "https://el-airbnb.vercel.app/",
		codeLink: "https://github.com/Djalalov/el-airbnb",
		imageUrl: images.project5,
		tags: ["React JS", "JavaScript"],
		stack: ["React Js", "CSS3", "Material UI"],
	},
	{
		title: "Music App",
		description: "A landing page for a music app.",
		projectLink: "https://el-music.vercel.app",
		codeLink: "https://github.com/Djalalov/music-app",
		imageUrl: images.project6,
		tags: ["React JS"],
		stack: ["ReactJS", "Tailwind", "FramerMotion", "Material UI"],
	},
	{
		title: "e-Store",
		description: "An elegant e-store with advanced filter options",
		projectLink: "https://el-store.vercel.app/",
		codeLink: "https://github.com/Djalalov/ecommerce-context-api",
		imageUrl: images.project7,
		tags: ["React JS", "JavaScript"],
		stack: ["ReactJS", "React-Bootstrap", "Context API"],
	},
];
